// SEO
import React, { useEffect, useState } from 'react';
import HeaderComp from './header';
import Footer from './footer';
import { PC, Mobile } from './mediaQuery';
import HeaderComp2 from './header2';

import * as styles from '../styles/layout.module.css';
import { amplitudeInit } from '../Lib/ampletude';
import { useSiteMetadata } from 'src/hooks/useSiteMetadata';

const Layout = ({
  children,
  headerBold,
  isMobileHeader,
  pageTitle,
  isFooter = true,
  pcClassName = styles.pcContainer,
  mClassName = styles.mContainer,
  isWeb,
}: any) => {
  const { title, copyRight, keywords, description, siteUrl, amplitudeKey, sharedImg } = useSiteMetadata();

  useEffect(() => {
    document.addEventListener('touchstart', function () {}, false);
    const ampKey = process.env.GATSBY_STAGE === 'production' || 'prod' ? amplitudeKey : '';
    amplitudeInit(ampKey);
  }, []);

  return (
    <div>
      {/* header */}
      {isMobileHeader ? (
        pageTitle.includes('매거진') && isWeb === 'true' ? (
          <HeaderComp2 title={pageTitle} />
        ) : (
          ''
        )
      ) : (
        <HeaderComp bold={headerBold} />
      )}

      <PC className={pcClassName}>
        <div
          style={{
            height: 90,
          }}
        />
        {children}
      </PC>
      <Mobile className={mClassName}>
        <div
          style={{
            height: isMobileHeader ? 0 : 65,
          }}
        />
        {children}
      </Mobile>

      {isFooter && <Footer />}
    </div>
  );
};

export default Layout;
