import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Mobile, PC } from '../components/mediaQuery';
import Layout from '../components/layout';
import { Link } from 'gatsby';

import * as styles from '../styles/eventList.module.css';
import { amplitudeLog } from '../Lib/ampletude';
import { getPlatform } from '../utils';
import { useSiteMetadata } from 'src/hooks/useSiteMetadata';
import { SEO } from './seo';

export default function EventListComponent({ path, events }: any) {
  const [cookies, setCookie] = useCookies([`nonePopup`]);
  const [activeFilter, setActiveFilter] = useState('all');
  const [downloadPopup, setDownloadPopup] = useState(true);

  const onClickFilter = (e: any) => {
    setActiveFilter(e.target.dataset.name);
  };

  /**
   * 만료기간이 된 이벤트는 안보여줌
   * @param event
   * @returns
   */
  const eventExpirationDateFilter = (event: any) => {
    return activeFilter === 'all' || new Date(event.expirationDate) > new Date();
  };

  const getRemainTime = (expirationDate: Date) => {
    let remainTime = expirationDate.getTime() - new Date().getTime();

    const days = Math.floor(remainTime / (1000 * 60 * 60 * 24));
    remainTime = remainTime % (1000 * 60 * 60 * 24);

    const hours = Math.floor(remainTime / (1000 * 60 * 60));
    remainTime = remainTime % (1000 * 60 * 60);

    const minutes = Math.floor(remainTime / (1000 * 60));

    return [days, hours, minutes];
  };

  const handleDownClick = (eventName: string) => {
    return () => {
      // amplitudeLog(`Main Click AppLink`, {
      //   type: eventName,
      // })
      amplitudeLog(`앱 설치 버튼`, {
        path: '이벤트',
        type: getPlatform(),
      });
      let gotoLink = 'https://momguide.page.link/landingapp';
      window.open(gotoLink);
    };
  };
  const handleClosePopup = () => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 1);
    setCookie('nonePopup', true, { expires });

    setDownloadPopup(!downloadPopup);
  };

  return (
    <Layout headerBold="event" pageTitle="이벤트">
      <div className={styles.container}>
        <PC className={styles.pc}>
          <h2>아무 제품이나 체험할 순 없으니깐,</h2>
          <h1>맘가이드 체험단</h1>
          <div className={styles.filter}>
            <span data-name="all" onClick={onClickFilter} className={activeFilter === 'all' ? styles.on : ''}>
              전체 이벤트
            </span>
            <span data-name="doing" onClick={onClickFilter} className={activeFilter === 'doing' ? styles.on : ''}>
              진행중인 이벤트
            </span>
          </div>
          <div className={styles.contentWrapper}>
            {events.filter(eventExpirationDateFilter).map((event) => {
              return (
                <Link key={event.id} to={`/events/${event.id}/`}>
                  <div className={styles.content}>
                    <img style={{ padding: 0, margin: 0 }} src={event.thumbnail + '?eventpcthumbnail'} />
                    <p className={styles.subTitle}>{event.title}</p>
                  </div>
                </Link>
              );
            })}
          </div>

          {/* PC popup */}
          <div
            id="popup-pc"
            style={{
              width: '30%',
              position: 'fixed',
              bottom: '1%',
              right: '1%',
              margin: '0 auto',
              zIndex: 200,
              display: cookies.nonePopup ? 'none' : 'inline',
              imageRendering: '-webkit-optimize-contrast',
            }}
          >
            <div style={{ position: 'relative', width: '100%', zIndex: 200 }}>
              <img style={{ width: '100%', zIndex: 200 }} src="/images/popup/popup_background.png" alt="" />
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '22.4%',
                  zIndex: 500,
                  width: '100%',
                }}
              >
                <img
                  style={{ width: '40%', zIndex: 500, cursor: 'pointer' }}
                  src="/images/popup/download_app.png"
                  onClick={handleDownClick('m_foot')}
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '1%',
                  left: '88%',
                  zIndex: 500,
                  width: '100%',
                }}
              >
                <img
                  style={{
                    width: '10%',
                    zIndex: 500,
                    cursor: 'pointer',
                    padding: 0,
                    margin: 0,
                  }}
                  src="/images/popup/cancel_btn.png"
                  alt=""
                  onClick={handleClosePopup}
                />
              </div>
            </div>
          </div>
        </PC>
        <Mobile className={styles.mobile}>
          <div className={styles.filter}>
            <span data-name="all" onClick={onClickFilter} className={activeFilter === 'all' ? styles.on : ''}>
              전체 이벤트
            </span>
            <span data-name="doing" onClick={onClickFilter} className={activeFilter === 'doing' ? styles.on : ''}>
              진행중인 이벤트
            </span>
          </div>
          <div className={styles.contentWrapper}>
            {events.filter(eventExpirationDateFilter).map((event) => {
              return (
                <div className={styles.content}>
                  <Link className={styles.link} key={event.id} to={`/events/${event.id}/`}>
                    <img src={event.thumbnail + '?eventmthumbnail'} />
                    <p className={styles.subTitle}>{event.title}</p>
                  </Link>
                </div>
              );
            })}
          </div>

          {/* mobile popup */}
          <div
            id="popup-mobile"
            style={{
              width: '100%',
              position: 'fixed',
              bottom: '1%',
              margin: '0 auto',
              zIndex: 200,
              display: cookies.nonePopup ? 'none' : 'inline',
              imageRendering: '-webkit-optimize-contrast',
            }}
          >
            <div style={{ position: 'relative', width: '100%', zIndex: 200 }}>
              <img style={{ width: '100%', zIndex: 200 }} src="/images/popup/popup_background.png" alt="" />
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '22.4%',
                  zIndex: 500,
                  width: '100%',
                }}
              >
                <img
                  style={{ width: '40%', zIndex: 500, cursor: 'pointer' }}
                  src="/images/popup/download_app.png"
                  onClick={handleDownClick('m_foot')}
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '1%',
                  left: '88%',
                  zIndex: 500,
                  width: '100%',
                }}
              >
                <img
                  style={{
                    width: '10%',
                    zIndex: 500,
                    cursor: 'pointer',
                    padding: 0,
                    margin: 0,
                  }}
                  src="/images/popup/cancel_btn.png"
                  alt=""
                  onClick={handleClosePopup}
                />
              </div>
            </div>
          </div>
        </Mobile>
      </div>
    </Layout>
  );
}

export const Head = ({ location, params, data, pageContext }: any) => {
  const { descriptionEvents, siteUrl } = useSiteMetadata();

  return (
    <SEO title="이벤트">
      <meta id="desc" name="description" content={descriptionEvents} />
      <link id="canonical_url" rel="canonical" href={`${siteUrl}/events/`} />
    </SEO>
  );
};
