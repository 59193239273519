import { Link } from 'gatsby';
import React from 'react';
import { Mobile, PC } from './mediaQuery';

import * as styles from '../styles/footer.module.css';
import { amplitudeLog } from '../Lib/ampletude';

const snsNaverUrl = 'https://post.naver.com/my.nhn?memberNo=45144884';
const snsInstaUrl = 'https://www.instagram.com/momguide.official/';
const snsYoutube = 'https://www.youtube.com/channel/UC1YNGrF7ALLpXs-AG3f4XCA';

const handleSNS = (type: string, url: string) => {
  return () => {
    amplitudeLog(`Footer Click SNS`, {
      type,
    });
    window.open(url);
  };
};

export default function Footer() {
  return (
    <div className={styles.container}>
      <PC className={styles.pc}>
        <div className={styles.wrapper}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Link to="/">
                <img alt="맘가이드 로고" className={styles.logo} src="/images/logo_mint_eng.png" />
              </Link>
            </div>
            <div className={styles.info}>
              <div>
                (주)인포그린 I 대표자 : 구진산 I 사업자등록번호 : 327-87-01114
                <br />
                서울특별시 서초구 방배로 180, 유중문화재단 404호(스파크플러스 방배점) I 문의{' '}
                <a href="mailto:momguide@momguide.co.kr" style={{ color: '#0AD3BD' }}>
                  momguide@momguide.co.kr
                </a>{' '}
                I 전화 070-5056-2592
              </div>
              <div style={{ height: '20px' }}></div>
              <div>ⓒ INFOGREEN lnc. ALL Rights Reserved</div>
              <div style={{ height: '20px' }}></div>
              <div className={styles.linkWrapper}>
                <Link to="/policies/service/">
                  <span className={styles.link}>이용약관</span>
                </Link>
                <div style={{ width: '20px' }}> </div>
                <Link to="/policies/privacy/">
                  <span className={styles.link}>개인정보 처리방침</span>
                </Link>
                <div style={{ width: '20px' }}> </div>
                <a href="https://infogreen.notion.site/momguidebusiness1" target="_blank" rel="noreferrer noopener">
                  <span className={styles.link}>비즈니스 문의</span>
                </a>
              </div>
            </div>
            <div>
              <div className={styles.snsIconWrapper}>
                <img
                  onClick={handleSNS('instagram', snsInstaUrl)}
                  className={styles.snsIcon}
                  src={'/images/icon_sns_insta.png'}
                  alt={'맘가이드 인스타그램 링크 아이콘'}
                />
                <img
                  onClick={handleSNS('youtube', snsYoutube)}
                  className={styles.snsIcon}
                  src={'/images/icon_sns_youtube.png'}
                  alt={'맘가이드 유튜브 링크 아이콘'}
                />
                <img
                  onClick={handleSNS('naver', snsNaverUrl)}
                  className={styles.snsIcon}
                  src={'/images/icon_sns_naver.png'}
                  alt={'맘가이드 네이버 링크 아이콘'}
                />
              </div>
            </div>
          </div>
        </div>
      </PC>
      <Mobile className={styles.mobile}>
        <div className={styles.wrapper}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '70%',
              width: '375px',
            }}
          >
            <div style={{ height: '25px' }}></div>

            <div className={styles.linkWrapper}>
              <Link to="/policies/service/" style={{ height: '100%' }}>
                <div className={styles.link}>이용약관</div>
              </Link>
              <Link to="/policies/privacy/">
                <div className={styles.link}>개인정보처리방침</div>
              </Link>
              <Link
                to="https://www.notion.so/infogreen/33f8c43c68bf4ed5addf1240898c5dbf?pvs=4"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className={styles.link}>비즈니스 문의</div>
              </Link>
            </div>
            <div style={{ height: '25px' }}></div>

            <div className={styles.info}>
              <div>(주)인포그린 I 대표자 : 구진산 I 사업자등록번호 : 327-87-01114</div>
              <div>서울특별시 서초구 방배로 180, 유중문화재단 404호(스파크플러스 방배점)</div>
              <div>
                문의&nbsp;
                <a href="mailto:momguide@momguide.co.kr" style={{ color: '#0AD3BD' }}>
                  momguide@momguide.co.kr
                </a>
                &nbsp;I 전화 070-5056-2592
              </div>
            </div>
            <div style={{ height: '35px' }}></div>

            <div className={styles.copyright}>ⓒ INFOGREEN lnc. ALL Rights Reserved</div>
          </div>
        </div>
      </Mobile>
    </div>
  );
}
