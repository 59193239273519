import React from 'react';
import { Mobile } from './mediaQuery';

import * as styles from '../styles/header2.module.css';

export default function HeaderComp2({ title }) {
  const onClickGoToBack = () => {
    window.history.back();
  };

  return (
    <Mobile className={styles.mobile}>
      {/* <div style={{ display: 'flex', flex: '1 1 auto', justifyContent: 'center', alignContent: 'center' }}></div> */}
      <div style={{ display: 'flex', flex: '1 1 auto', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
        <img style={{ cursor: 'pointer' }} onClick={onClickGoToBack} src="/images/arrow_left.svg" /> {title}
      </div>
    </Mobile>
  );
}
