import React, { useEffect, useState } from 'react';
import { useSiteMetadata } from 'src/hooks/useSiteMetadata';

export const SEO = ({
  title,
  description,
  pageUrl,
  pathname,
  children,
}: {
  title?: string;
  description?: string;
  pageUrl?: string;
  pathname?: string;
  children?: any;
}) => {
  const {
    title: defaultTitle,
    copyRight,
    keywords,
    description: defaultDescription,
    siteUrl,
    amplitudeKey,
    sharedImg,
    appLinkApple,
    appLinkGoogle,
    appPckgName,
  } = useSiteMetadata();

  const seoContext = {
    title: `${title ? `${title} - ` : ``}${defaultTitle}`,
    description: description || defaultDescription,
    url: pageUrl || siteUrl,
  };

  return (
    <>
      <title>{seoContext.title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta charSet="utf-8" />

      <meta id="desc" name="description" content={seoContext.description} />
      <meta name="Keywords" content={keywords} />
      <meta name="robots" content="index,follow" />
      <meta name="author" content={defaultTitle} />
      <meta name="copyright" content={copyRight} />
      <meta name="subject" content={defaultTitle} />
      <meta id="og_url" property="og:url" content={seoContext.url} />
      <meta property="og:type" content="website" />
      <meta id="og_title" property="og:title" content={defaultTitle} />
      <meta id="og_desc" property="og:description" content={seoContext.description} />
      <meta property="og:image" content={sharedImg} />
      <meta name="naver-site-verification" content="f50483029ef786cd02c68ff514373034568e9ec6" />
      <meta name="google-site-verification" content="tNTISURBGq34gqaijbhubEnD5JeJNB8KHylw_R-Ksy4" />
      <link rel="shortcut icon" href="https://momguide.co.kr/favicon.ico" type="image/x-icon"></link>
      <link id="canonical_url" rel="canonical" href={seoContext.url} />

      {/* ios */}
      <meta property="al:ios:url" content={appLinkApple} />
      <meta property="al:ios:app_store_id" content={appPckgName} />
      <meta property="al:ios:app_name" content={defaultTitle} />

      {/* android */}
      <meta property="al:android:url" content={appLinkGoogle} />
      <meta property="al:android:app_name" content={defaultTitle} />
      <meta property="al:android:package" content={appPckgName} />
      <meta property="al:web:url" content={appLinkGoogle} />

      {children}
    </>
  );
};
