// extracted by mini-css-extract-plugin
export var container = "footer-module--container--9ae22";
export var copyright = "footer-module--copyright--43e5e";
export var info = "footer-module--info--6448e";
export var infoTitle = "footer-module--infoTitle--f3a83";
export var link = "footer-module--link--08adf";
export var linkWrapper = "footer-module--linkWrapper--92d11";
export var logo = "footer-module--logo--bf967";
export var mobile = "footer-module--mobile--44044";
export var pc = "footer-module--pc--5c0ce";
export var snsIcon = "footer-module--snsIcon--619ec";
export var snsIconWrapper = "footer-module--snsIconWrapper--5c510";
export var wrapper = "footer-module--wrapper--1f26a";