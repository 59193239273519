import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import EventListComponent from '../components/eventList';
import EventDetailComponent from '../components/eventDetail';
import { navigate } from '@reach/router';
import axios from 'axios';
import { amplitudeLog } from '../Lib/ampletude';
import { getPlatform } from '../utils';
import { useSiteMetadata } from 'src/hooks/useSiteMetadata';
import { PageProps } from 'gatsby';
import { SEO } from 'src/components/seo';

// 빌드된 이후에 추가된 이벤트들에 대해서 동적으로 데이터를 받아와 렌더링

export default function EventList({ pageContext: { events, lastestEventId }, params }) {
  const [event, setEvent] = useState(null as any);
  const [totalEvents, setTotalEvents] = useState([] as any);

  const { apiUrl } = useSiteMetadata();

  async function fetchNewEvents(latestId: number) {
    const queryParams = {
      params: {
        category: 'event',
        filter: '',
        page: 0,
        limit: 99,
        order: 'lastest',
        newerThan: latestId,
      },
    };
    const momResponse = await axios.get(`${apiUrl}/v1/boards`, queryParams);
    const petResponse = await axios.get(`${apiUrl}/pet/v1/boards`, queryParams);

    const merged = [...momResponse.data.data, ...petResponse.data.data];
    const result = [] as any[];
    const map = new Map();
    for (const item of merged) {
      if (!map.has(item.id)) {
        map.set(item.id, true);
        result.push(item);
      }
    }
    // return result.sort((a, b) => b.id - a.id)
    return result;
  }

  useEffect(() => {
    const eventId = params['*'];
    if (eventId) {
      axios
        .get(`${apiUrl}/v1/boards/detail/${eventId}`)
        .then((response) => setEvent(response.data.data))
        .catch((err) => {
          navigate('/events');
        });
      amplitudeLog(`Event Detail View`, {
        id: eventId,
        type: getPlatform(),
      });
    } else {
      fetchNewEvents(lastestEventId)
        .then((data) => {
          const sortedByExpirationDateList = [...data, ...events].sort((a, b) => b.expirationDate - a.expirationDate);
          setTotalEvents(sortedByExpirationDateList);
        })
        .catch((err) => {
          navigate('/events');
        });
      amplitudeLog(`Event List View`, { type: getPlatform() });
    }
  }, [params]);

  return (
    <Router basepath="/events">
      <EventListComponent path="/" events={totalEvents} />
      <EventDetailComponent path="/:eventId" event={event} />
    </Router>
  );
}

// 이벤트 목록 화면 Head
export const Head = (props: PageProps) => {
  const { data, location, pageContext, params, serverData, ...rest } = props;
  const { descriptionEvents, siteUrl } = useSiteMetadata();

  const title = `이벤트`;
  const eventDescription = descriptionEvents;
  const pageUrl = `${siteUrl}${location.pathname}`;

  return (
    <SEO title={title} description={eventDescription} pageUrl={pageUrl}>
      <meta name="og:title" property="og:title" content={title} />
    </SEO>
  );
};
