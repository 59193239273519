import React, { useState } from 'react';
import { Mobile, PC, useIsMobile } from './mediaQuery';
import Layout from './layout';
import moment from 'moment';
import { useCookies } from 'react-cookie';

import * as styles from '../styles/event.module.css';
import { amplitudeLog } from '../Lib/ampletude';
import { getPlatform } from '../utils';

const getRemainTime = (expirationDate: Date) => {
  let remainTime = expirationDate.getTime() - new Date().getTime();

  const days = Math.floor(remainTime / (1000 * 60 * 60 * 24));
  remainTime = remainTime % (1000 * 60 * 60 * 24);

  const hours = Math.floor(remainTime / (1000 * 60 * 60));
  remainTime = remainTime % (1000 * 60 * 60);

  const minutes = Math.floor(remainTime / (1000 * 60));

  return [days, hours, minutes];
};

export default function EventDetailComponent({ event, ...rest }) {
  const isMobile = useIsMobile();
  const [cookies, setCookie] = useCookies([`nonePopup`]);
  const [downloadPopup, setDownloadPopup] = useState(true);

  if (!event) {
    return null;
  }

  const handleDownClick = (eventName: string) => {
    return () => {
      amplitudeLog(`앱 설치 버튼`, {
        path: '이벤트',
        type: getPlatform(),
      });
      let gotoLink = 'https://momguide.page.link/landingapp';
      window.open(gotoLink);
    };
  };
  const handleDownloadPopup = () => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 1);
    setCookie('nonePopup', true, { expires });

    setDownloadPopup(!downloadPopup);
  };

  return (
    <Layout headerBold="event" isMobileHeader={isMobile} pageTitle={`${event.title ? `${event.title} - ` : ''} 이벤트`}>
      <PC className={styles.pc}>
        <p className={styles.remainTime}>
          {new Date(event.expirationDate) < new Date() ? (
            <span className={styles.colored}>종료된 이벤트</span>
          ) : (
            getRemainTime(new Date(event.expirationDate)).map((time, idx) => {
              let unit = '';
              if (idx === 0) unit = '일';
              else if (idx === 1) unit = '시간';
              else if (idx === 2) unit = '분';
              return (
                <>
                  <span className={styles.colored}>&nbsp;{time}</span>
                  <span>{unit}</span>
                </>
              );
            })
          )}
        </p>
        <h1 className={styles.title}>{event.title}</h1>
        <p className={styles.createdAt}>{moment(event.createdAt).format('YYYY.MM.DD')}</p>
        {/* TODO: img2Arr로 교체 */}
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: event.content }}></div>

        {/* PC popup */}
        <div
          style={{
            width: '30%',
            position: 'fixed',
            bottom: '1%',
            right: '1%',
            margin: '0 auto',
            zIndex: 200,
            display: cookies.nonePopup ? 'none' : 'inline',
            imageRendering: '-webkit-optimize-contrast',
          }}
        >
          <div style={{ position: 'relative', width: '100%', zIndex: 200 }}>
            <img style={{ width: '100%', zIndex: 200 }} src="/images/popup/popup_background.png" alt="" />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '22.4%',
                zIndex: 500,
                width: '100%',
              }}
            >
              <img
                style={{ width: '40%', zIndex: 500, cursor: 'pointer' }}
                src="/images/popup/download_app.png"
                onClick={handleDownClick('m_foot')}
              />
            </div>
            <div
              style={{
                position: 'absolute',
                top: '1%',
                left: '88%',
                zIndex: 500,
                width: '100%',
              }}
            >
              <img
                style={{
                  width: '10%',
                  zIndex: 500,
                  cursor: 'pointer',
                  padding: 0,
                  margin: 0,
                }}
                src="/images/popup/cancel_btn.png"
                alt=""
                onClick={handleDownloadPopup}
              />
            </div>
          </div>
        </div>
      </PC>
      <Mobile className={styles.mobile}>
        <p className={styles.remainTime}>
          {new Date(event.expirationDate) < new Date() ? (
            <span className={styles.colored}>종료된 이벤트</span>
          ) : (
            getRemainTime(new Date(event.expirationDate)).map((time, idx) => {
              let unit = '';
              if (idx === 0) unit = '일';
              else if (idx === 1) unit = '시간';
              else if (idx === 2) unit = '분';
              return (
                <>
                  <span className={styles.colored}>&nbsp;{time}</span>
                  <span>{unit}</span>
                </>
              );
            })
          )}
        </p>
        <h1 className={styles.title}>{event.title}</h1>
        <p className={styles.createdAt}>{moment(event.createdAt).format('YYYY.MM.DD')}</p>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: event.content }}></div>

        {/* mobile popup */}
        <div
          style={{
            width: '100%',
            position: 'fixed',
            bottom: '1%',
            margin: '0 auto',
            zIndex: 200,
            display: cookies.nonePopup ? 'none' : 'inline',
            imageRendering: '-webkit-optimize-contrast',
          }}
        >
          <div style={{ position: 'relative', width: '100%', zIndex: 200 }}>
            <img style={{ width: '100%', zIndex: 200 }} src="/images/popup/popup_background.png" alt="" />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '22.4%',
                zIndex: 500,
                width: '100%',
              }}
            >
              <img
                style={{ width: '40%', zIndex: 500, cursor: 'pointer' }}
                src="/images/popup/download_app.png"
                onClick={handleDownClick('m_foot')}
              />
            </div>
            <div
              style={{
                position: 'absolute',
                top: '1%',
                left: '88%',
                zIndex: 500,
                width: '100%',
              }}
            >
              <img
                style={{
                  width: '10%',
                  zIndex: 500,
                  cursor: 'pointer',
                  padding: 0,
                  margin: 0,
                }}
                src="/images/popup/cancel_btn.png"
                alt=""
                onClick={handleDownloadPopup}
              />
            </div>
          </div>
        </div>
      </Mobile>
    </Layout>
  );
}
